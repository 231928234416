import React, { Component, useEffect, useState } from 'react'
import Header from '../components/Header.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons'
import "./Home.css"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const BASE_URL = "https://prod.brilliantpad.com/"

function Home() {

    const [data, setData] = useState([])
    const [count, setCount] = useState(0)
    const [fetched, setFetched] = useState(false)
    const [offset, setOffset] = useState(5)
    const [page, setPage] = useState(1)

    let history = useNavigate()

    useEffect(() => {
        
        fetchData()
        
    }, []);

    function fetchData() {

        if (check_refresh_token_expiry()) {
            console.log("Refresh Token expired")
            alert('User session expired')
            history('/')
        } else if (check_token_expiry()) {
            console.log("Access Token expired")
            refresh_token(function (accessToken, accessTokenExpiry, refreshToken, refreshTokenExpiry, refreshTokenId) {
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('accessTokenExpiry', accessTokenExpiry);
                localStorage.setItem('refreshToken', refreshToken);
                localStorage.setItem('refreshTokenExpiry', refreshTokenExpiry);
                localStorage.setItem('refreshTokenId', refreshTokenId);
                console.log("Local storage data renewed")
                get_details(function (info, count) {
                    setData(info)
                    setCount(count)
                    setFetched(true)
                });
                console.log(data)
                
            });

        } else {
            get_details(function (info, count) {
                setData(info)
                setCount(count)
                setFetched(true)
                console.log(data)
            });
            console.log(data)


        }

    }

    function get_details(callback) {
        let body = {
            "offset": offset,
            "page": page
        }
        const prom = axios({ method: 'get', url: BASE_URL + 'user/previousnotificaitons', params: body, headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") } })
        prom.then((response) => {
            console.log(response.data.result['notification details']['notifications'])
            callback(response.data.result['notification details']['notifications'], response.data.result['notification details']['total_notifications'])
        })
        prom.catch((error) => {
            console.log(error)
        })

    }

    function check_token_expiry() {
        var accessTokenExpiry = localStorage.getItem("accessTokenExpiry")
        accessTokenExpiry = new Date(accessTokenExpiry)
        let curr_time = new Date();
        if (curr_time > accessTokenExpiry) {
            return true
        }
        return false
    }

    function check_refresh_token_expiry() {
        var refreshTokenExpiry = localStorage.getItem("refreshTokenExpiry")
        refreshTokenExpiry = new Date(refreshTokenExpiry)
        let current_time = new Date();
        if (current_time > refreshTokenExpiry) {
            return true
        }
        return false
    }

    function refresh_token(callback) {

        let body = {
            "refreshToken": localStorage.getItem("refreshToken"),
            "refreshTokenId": localStorage.getItem("refreshTokenId")
        }
        axios.post(BASE_URL + "user/refresh_token", body).then(success => {
            const base_token = success.data.result.token_details
            console.log(base_token)
            var accessToken = base_token.accessToken
            var accessTokenExpiry = base_token.accessTokenExpiry
            var refreshToken = base_token.refreshToken
            var refreshTokenExpiry = base_token.refreshTokenExpiry
            var refreshTokenId = base_token.refreshTokenId
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('accessTokenExpiry', accessTokenExpiry);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('refreshTokenExpiry', refreshTokenExpiry);
            localStorage.setItem('refreshTokenId', refreshTokenId);
            console.log("Token refreshed")
            callback(accessToken, accessTokenExpiry, refreshToken, refreshTokenExpiry, refreshTokenId)
            return true


        }).catch(error => {
            console.error(error);
            alert('User session expired')
            history('/')

        })

    }

    function create_notification() {
        history('/notification')
    }

    function handleChangePage(event, value) {
        setPage(value+1)
        fetchData()
    }

    return (
        <div class="container-fluid sec-wrap">
            <Header></Header>
            <div class="container-fluid table-section">

                <div class="container-fluid table-head">
                    <div class="row">
                        <div class="col-md-9 table-title-wrap"><h6 id="table-title">Recent Push Notification</h6></div>
                        {/* <div class="col-md-2">
                            <div class="container-fluid search">
                                <div class="row search-row">
                                    <div class="col-2 search-icon"><FontAwesomeIcon icon={faSearch} color="#DADADA" /></div>
                                    <div class="col-10"><input type="text" class="form-control form-input search-input" placeholder="Search..."></input></div>
                                </div>
                            </div>
                        </div> */}
                        <div class="col-md-3 notify-button-wrap">
                            <button type="button" class="btn btn-primary notify-button" onClick={create_notification}><FontAwesomeIcon icon={faPlus} color="#ffff" />&nbsp;&nbsp;Create Push Notification</button>
                        </div>
                    </div>
                </div>
                {fetched?
                <div class="table-responsive-lg">
                    <TableContainer component={Paper}>
                        <Table size='larger' aria-label="a dense table" class="table table-striped table-dark">
                            <TableHead>
                                <TableRow>
                                    <TableCell class="text-nowrap">ID</TableCell>
                                    <TableCell class="text-nowrap">Notification Title</TableCell>
                                    <TableCell class="text-nowrap">Description</TableCell>
                                    <TableCell class="text-nowrap">Image</TableCell>
                                    <TableCell class="text-nowrap">User Email</TableCell>
                                    <TableCell class="text-nowrap">User Name</TableCell>
                                </TableRow>
                            </TableHead>
                             
                            <TableBody>
                                {data.map((row) => (
                                    <TableRow
                                        key={row.notification_id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{row.notification_id}</TableCell>
                                        <TableCell>{row.notification_title}</TableCell>
                                        <TableCell>{row.notification_message}</TableCell>
                                        <TableCell><img src={row.image_url} width="32px" height="32px"></img></TableCell>
                                        <TableCell>{row.user_email}</TableCell>
                                        <TableCell>{row.user_name}</TableCell>

                                        
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        
                    </TableContainer>

                </div>: <Box sx={{ width: '100%' }}><LinearProgress /></Box>}
                <TablePagination
                            rowsPerPageOptions={[5]}
                            class="pagination"
                            component="div"
                            count={Math.ceil(count/5)}
                            rowsPerPage={5}
                            page={page-1}
                            onPageChange={handleChangePage}
                        />

            </div>

        </div>
    )
}

export default Home