import React from 'react'
import './Notify.css'
import Header from '../components/Header.js'
import { useState } from 'react'
import axios from 'axios'
import { useNavigate, Navigate } from 'react-router'
import mobile_img from "../assets/images/Mobile.png";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';


function Notify() {

  let history = useNavigate();
  const [Email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [image, setimage] = useState(null);
  const [long_description, setlong_description] = useState("");
  const [click_url, setclick_url] = useState("");
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(true);
  const [BASE_URL, setBASE_URL] = useState("https://prod.brilliantpad.com/")
  const [image_url, setimage_url] = useState("")

  function cancelForm() {
    history('/dashboard');
  }

  function submitForm() {
    setLoading(true)
    console.log(BASE_URL)

    let body = {
      "email_or_reference_token": Email,
      "title": title,
      "image": image,
      "long_description": long_description,
      "click_url": click_url
    }
    console.log("Input")
    console.log(body)
    if (check_refresh_token_expiry()) {
      alert('User session expired')
      history('/')
    } else if (check_token_expiry) {
      let a = refresh_token(function (accessToken, accessTokenExpiry, refreshToken, refreshTokenExpiry, refreshTokenId) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('accessTokenExpiry', accessTokenExpiry);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('refreshTokenExpiry', refreshTokenExpiry);
        localStorage.setItem('refreshTokenId', refreshTokenId);

        axios.post(BASE_URL + "user/send_notification", body, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
          }
        }).then(success => {
          setLoading(false);
          console.log("Notification sent")
          alert("Notification Sent Successfully")

        }).catch(error => {
          console.log(error)
          alert("Something went wrong. Try after sometime")
          history('/dashboard')
        })


      });

    } else {

      console.log(localStorage.getItem("accessToken"))
      axios.post(BASE_URL + "user/send_notification", body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
        }
      }).then(success => {
        setLoading(false);
        console.log("Notification sent")
        alert("Notification Sent Successfully")

      }).catch(error => {
        console.log(error)
        alert("Something went wrong. Try after sometime")
        history('/dashboard')
      })

    }

  }

  function check_token_expiry() {
    var accessTokenExpiry = localStorage.getItem("accessTokenExpiry")
    accessTokenExpiry = new Date(accessTokenExpiry)
    let current_time = new Date();
    if (current_time > accessTokenExpiry) {
      return true
    }
    return false
  }

  function check_refresh_token_expiry() {
    var refreshTokenExpiry = localStorage.getItem("refreshTokenExpiry")
    refreshTokenExpiry = new Date(refreshTokenExpiry)
    let current_time = new Date();
    if (current_time > refreshTokenExpiry) {
      return true
    }
    return false
  }

  function refresh_token(callback) {

    let body = {
      "refreshToken": localStorage.getItem("refreshToken"),
      "refreshTokenId": localStorage.getItem("refreshTokenId")
    }
    axios.post(BASE_URL + "user/refresh_token", body).then(success => {
      const base_token = success.data.result.token_details
      console.log(base_token)
      var accessToken = base_token.accessToken
      var accessTokenExpiry = base_token.accessTokenExpiry
      var refreshToken = base_token.refreshToken
      var refreshTokenExpiry = base_token.refreshTokenExpiry
      var refreshTokenId = base_token.refreshTokenId
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiry', accessTokenExpiry);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('refreshTokenExpiry', refreshTokenExpiry);
      localStorage.setItem('refreshTokenId', refreshTokenId);
      console.log("Token refreshed")
      callback(accessToken, accessTokenExpiry, refreshToken, refreshTokenExpiry, refreshTokenId)
      return true


    }).catch(error => {
      console.error(error);
      alert('User session expired')
      history('/')

    })

  }



  return (
    <div class="container-fluid section-wrap">
      <Header />
      <div class="container-fluid">
        <div class="row section-row">
          <div class="col-sm-7">
            <form id="notification-form">

              <h6 id="notification">Push Notification</h6>

              <div class="form-group">
                <label class="labels" for="exampleInputText1">TITLE</label>
                <input type="text" class="form-control inp" id="exampleInputText1" placeholder="Enter title" onChange={(e) => setTitle(e.target.value)}></input>
              </div>

              <div class="form-group">
                <label class="labels" for="exampleInputText2">DESCRIPTION</label>
                <textarea type="text" class="form-control inp" id="exampleInputText2" placeholder="Enter description" onChange={(e) => setlong_description(e.target.value)}></textarea>
              </div>

              <div class="form-group">
                <label class="labels" for="exampleFormControlFile1">IMAGE</label><br></br>
                <input type="file" class="form-control-file inp" id="exampleFormControlFile1" onChange={(e) => { setimage(e.target.files[0]); setimage_url(URL.createObjectURL(e.target.files[0])) }}></input>
              </div>

              <div class="form-group">
                <label class="labels" for="exampleFormControlFile2">URL</label>
                <input type="url" class="form-control inp" id="exampleFormControlFile2" placeholder="Paste URL" onChange={(e) => setclick_url(e.target.value)}></input>
              </div>

              <div class="form-group">
                <label class="labels" for="exampleFormControlFile3">TARGET AUDIENCE</label><br></br>
                {/* <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" defaultChecked="true" onChange={(e) => setBASE_URL("http://3.132.126.73:8111/api/")}></input>
                  <label class="form-check-label" for="inlineRadio1">Testing</label>
                </div>
                <div class="form-check form-check-inline inp">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" onChange={(e) => setBASE_URL("https://prod.brilliantpad.com/")}></input>
                  <label class="form-check-label" for="inlineRadio2">Production</label>
                </div> */}
                <input type="email" class="form-control inp" id="exampleInputText3" placeholder="Enter email ID or Reference Token" onChange={(e) => setEmail(e.target.value)}></input>
              </div>

            </form>
          </div>
          <div class="col-sm-5">
            <div class="form-check form-switch togg">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked="true" onChange={(e) => setPreview(!preview)}></input>
              <label class="form-check-label lab" for="flexSwitchCheckChecked">Show Preview</label>
            </div>
            {preview ? <div class="mobile_wrap"><div class="preview" style={{ backgroundImage: `url(${mobile_img})` }}><Card class="card" sx={{ maxWidth: 345 }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    <img class="avatar_img" src={image_url}></img>
                  </Avatar>
                }

                title={title}
                subheader={long_description}
              /></Card></div></div> : ""}

          </div>
          <hr class="solid"></hr>

        </div>

        <div class="row section-row1">
          <div class="col-5 buttons-sec">
            <button class="btn btn-primary btn-block mb-4 cancel" type='submit' onClick={cancelForm}>Cancel</button>
            <button class="btn btn-primary btn-block mb-4 send" onClick={submitForm}>{loading ? <div class="spinner-border spinner-border-sm"></div> : "Send"}</button>

          </div>

        </div>

      </div>

    </div>
  )
}

export default Notify