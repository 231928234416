import './App.css';
import Login from './pages/Login.js';
import Home from './pages/Home.js';
import Notify from './pages/Notify.js';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoutes from './components/ProtectedRoute'

function App() {

  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route element={<ProtectedRoutes />}>
        <Route path="dashboard" element={<Home />}></Route>
        <Route path="notification" element={<Notify />}></Route>
      </Route>

    </Routes>
  );
}

export default App;
