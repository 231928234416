import React, { Component } from 'react'
import "./Header.css"
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function Header() {

  let navigate = useNavigate();
  

  function logout_user(){
    
    let body = {}
    let accessToken = localStorage.getItem("accessToken")
    let header = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + accessToken
      }
    }
    
    axios.post("https://prod.brilliantpad.com/user/logout", body, header).then(success=>{
            console.log('Logging out');
            localStorage.removeItem("authenticated")            
            navigate("/")
          }).catch(error=>{
            localStorage.removeItem("authenticated")
            navigate("/")
            console.log(error);
           
          })


  }

  return (
    <div class="container-fluid head">        
            <nav class="navbar">
                <a class="navbar-brand">
                    <img src={require('../assets/images/logo.png')} width="40" height="40" alt=""></img>
                </a>
                <button class="logout" onClick={logout_user}>Logout</button>
            </nav>

        </div> 
  )
}

export default Header