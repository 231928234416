import React, { useState, useEffect } from 'react'
import "./Login.css"
import "bootstrap/dist/css/bootstrap.css"
import { useNavigate } from 'react-router-dom';
import { GraphQLClient, gql } from 'graphql-request'
import axios from "axios";
import { useLocation } from 'react-router'

function Login() {

  useEffect(() => {

    if (localStorage.getItem('authenticated')){
      navigate('/dashboard')
    }

  }, []);

  var navigate = useNavigate();

  const [Loading, setLoading] = useState(false);
  const location = useLocation()

  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Remember, SetRemember] = useState(false);

  const login_route = "http://3.132.126.73:8111/user/users"

  const END_POINT = 'https://brilliantpad.myshopify.com/api/graphql.json'
  const query = gql`mutation CustomerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {

      customerAccessTokenCreate(input: $input){       
          customerAccessToken{
          accessToken
          expiresAt
          }
          
          customerUserErrors{
          code
          message
          }
          }
      }      
      `;
  const variables = {
    input: { "email": "", "password": "" }
  }
  function login_user() {

    setLoading(true);

    const graphqQLClient = new GraphQLClient(END_POINT, {
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": "d9ebe2204851fe6c488aa8f56794df23"

      }
    })
    variables.input.email = Email
    variables.input.password = Password
    graphqQLClient.request(query, variables).then(response => {
      console.log(response)
      var shopify_token = response.customerAccessTokenCreate.customerAccessToken.accessToken
      var query_for_shopify_id = gql`{
          customer(customerAccessToken:"${shopify_token}"){
                  id 
      }
      }
      `
      graphqQLClient.request(query_for_shopify_id).then(success => {
        var userid = success.customer.id
        console.log(`id of the customer is ${userid}`)
        let header = {
          "Content-Type": "application/json",
        };
        let body = {
          shopify_access_token: shopify_token,
          email: 'praveena@cumulations.com',
          app_version: 1,
          userid: userid,
          notification_token: "abcdfsfsf",
          rolls_count_at_home: 3,
          address: "home",
          name: "srikanth"
        }
        axios.post("https://prod.brilliantpad.com/user/users", body).then(success => {
          console.log("this is called.")
          const base_token = success.data.result.login_token
          var accessToken = base_token.accessToken
          var accessTokenExpiry = base_token.accessTokenExpiry
          var refreshToken = base_token.refreshToken
          var refreshTokenExpiry = base_token.refreshTokenExpiry
          var refreshTokenId = base_token.refreshTokenId
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('accessTokenExpiry', accessTokenExpiry);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('refreshTokenExpiry', refreshTokenExpiry);
          localStorage.setItem('refreshTokenId', refreshTokenId);
          console.log("Access token  " + accessToken)
          console.log("Refresh token  " + refreshToken)
          const users = ["praveena@cumulations.com", "alan@brilliantpet.com"]
          if (!users.includes(Email)) {
            alert("Invalid credentials")
          } else {
            setLoading(false);
            localStorage.setItem("authenticated", "true");
            // if (location.state?.from){
            //   navigate(location.state.from)
            // }
            navigate("/dashboard", { replace: true })
          }

        }).catch(error => {
          setLoading(false);
          alert("Invalid credentials")
          console.log(error);

        })

      }).catch(error => {
        setLoading(false);
        alert("Invalid credentials")
        console.error(error);
      })

    }).catch(error => {
      setLoading(false);
      alert("Invalid credentials")
    })

  }


  return (
    <div class="container-fluid sec-wrap">


      <div class="row sec">
        <div class="col-lg-8 col-md-7 first">
          <img src={require('../assets/images/login.png')} id="login-img" class="img-responsive"></img>
        </div>
        <div class="col-lg-4 col-md-5 second">
          <form id="login-form">

            <h5 class="mb-3 login-title">SIGN IN</h5>

            <div class="form-outline mb-3">
              <input type="email" placeholder='Email Address' id="form2Example1" class="form-control" onChange={(e) => setEmail(e.target.value)} value={Email} />

            </div>


            <div class="form-outline mb-3">
              <input type="password" placeholder='Password' id="form2Example2" class="form-control" onChange={(e) => setPassword(e.target.value)} value={Password} />

            </div>


            <div class="row mb-3">
              <div class="col-6 d-flex justify-content-center">

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="form2Example31" onChange={(e) => SetRemember(!Remember)} checked={Remember} />
                  <label class="form-check-label" for="form2Example31"> Remember me </label>
                </div>
              </div>

              <div class="col-6 forgot">
                <a href="#">Forgot password?</a>
              </div>
            </div>


            <button type="button" onClick={login_user} class="btn btn-primary btn-block mb-4 sub">{Loading ? <div class="spinner-border spinner-border-sm"></div> : "SIGN IN"}</button>

          </form>

        </div>
      </div>
    </div>
  )
}

export default Login